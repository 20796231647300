<template>
  <form novalidate @submit.prevent="validateUser">
    <div class="md-layout">
      <div class="md-layout-item md-size-100 md-xsmall-size-100">
        <md-card >
          <md-card-header>
            <md-card-header-text>
              <div class="md-title" v-if="instance.id">Editando rubro</div>
              <div class="md-title" v-if="!instance.id">Nuevo rubro</div>
            </md-card-header-text>
            <md-menu md-size="big" md-direction="bottom-end">
              <md-button class="md-icon-button" @click="go_to('/rubros')">
                <md-icon>keyboard_backspace</md-icon>
              </md-button>
            </md-menu>
          </md-card-header>
          <md-card-content>
            <div class="md-layout md-gutter">
              <div class="md-layout-item md-small-size-100">
                <md-field >
                  <md-input type="text" name="name"
                  id="name" v-model="instance.name" :disabled="sending" />
                  <span class="md-error"
                  v-if="!$v.instance.name.required">EL nombre es requerido</span>
                </md-field>
              </div>
              <div class="md-layout-item md-small-size-100">
                <br>
                <md-switch v-model="instance.tipo_rubro">
                  <span v-if="instance.tipo_rubro">COMPRA</span>
                  <span v-if="!instance.tipo_rubro">VENTA</span>
                </md-switch>
                <span class="md-error"
                v-if="!$v.instance.tipo_rubro.required">EL tipo es requerido</span>
              </div>
            </div>
          </md-card-content>
          <md-progress-bar md-mode="indeterminate" v-if="sending" />
          <md-card-actions>
            <md-button
            type="submit" class="md-primary" :disabled="sending">Guardar cambios</md-button>
          </md-card-actions>
        </md-card>
      </div>
      <div class="md-layout-item md-size-50">
      </div>
      <md-snackbar
        :md-active.sync="userSaved">El documento {{ docuemt }} ha sido actualizado!</md-snackbar>
    </div>
  </form>
</template>
<script>
/* eslint-disable */
import { validationMixin } from 'vuelidate'
import {
  required,
  email,
} from 'vuelidate/lib/validators'

export default {
  name: 'FormRubros',
  created(){
    if (this.$route.params.id) {
      this.instance.id = this.$route.params.id
      this.retrieveInvoice();
    }
  },
  mixins: [validationMixin],
  data() {
    return {
      list_customer: [],
      list_store: [],
      list_rubros: [],
      list_libros: [],
      url_customer: 'customers/',
      url_stores: 'stores/',
      url_rubros: 'rubros/',
      url_libros: 'sale_book/',
      base: process.env.VUE_APP_BASE_URL,
      instance: {
        name: null,
        tipo_rubro: false,
      },
      url: 'rubros/',
      userSaved: false,
      sending: false,
      docuemt: null,
      choosen_type_document: [
      {value:'D', text: 'DTE FACTURA'},
      {value:'F', text: 'FACTURA'},
      {value:'N', text: 'DTE NOTA CREDITO'},
      {value:'C', text: 'NOTA CREDITO'},
      {value:'M', text: 'COMPROBANTE'},
      {value:'E', text: 'DTE FACTURA ESPECIAL'},
      {value:'S', text: 'FACTURA ESPECIAL'},
      {value:'P', text: 'DET FACTURA PEQUEÑO CONTRIBUYENTE'},
      {value:'Q', text: 'FACTURA PEQUEÑO CONTRIBUYENTE'},
      ]
    };
  },
  validations: {
    instance: {
      name: {
        required,
      },
      tipo_rubro: {
        required,
      }
    },
  },
  methods: {
    get_afecto (iva_incluido=true){
      if (iva_incluido){
        this.instance.iva = parseFloat(this.instance.amount * 0.12).toFixed(2)
        this.instance.base = parseFloat(this.instance.amount - this.instance.iva).toFixed(2)
      }
    },
    set_customer (obj) {
      this.instance.customer_name = obj.full_name
      this.instance.customer = obj.id
    },
    set_rubro (obj) {
      this.instance.rubro_name = obj.name
      this.instance.rubro = obj.id
    },
    go_to(url) {
      this.$router.push(url);
    },
    save_storage(key, data) {
      localStorage.setItem([key], JSON.stringify(data));
    },
    handlerError: (error) => {
      const result = error.response.data;
      const self = this;
      if (typeof (error.response.data) === 'object') {
        Object.entries(result).forEach(([key, value]) => { window.$swal.fire('Error!', `${key}: ${value}`, 'error'); });
        return false;
      }
      this.$swal.fire('Error!', result, 'error');
      return false;
    },
    list() {
      let payload = {}
      const self = this;
      this.sendRequest(payload, this.url).then((data) => {
        self.list_data = data.data;
      }).catch((error) => {
        console.log(error);
        this.$swal.fire('Error!', 'No fue posible obtener los registros.', 'error');
      });
      return null;
    },
    get_list_customers(searchTerm) {
      const payload = {
        token: searchTerm,
        filter_customer: "True"
      };
      const self = this;
      this.sendRequest(payload, this.url_customer).then((data) => {
        self.list_customer = data.data;
      }).catch((error) => {
        console.log(error);
        this.$swal.fire('Error!', 'No fue posible obtener los registros.', 'error');
      });
      return null;
    },
    get_list_stores(searchTerm=null) {
      const payload = {};
      if (searchTerm) {
        payload.toke = searchTerm
        payload.filter = "True"
      }
      filter: "True"
      const self = this;
      this.sendRequest(payload, this.url_stores).then((data) => {
        self.list_store = data.data;
      }).catch((error) => {
        console.log(error);
        this.$swal.fire('Error!', 'No fue posible obtener los registros.', 'error');
      });
      return null;
    },
    get_list_rubros(searchTerm=null) {
      let payload = {};
      if (searchTerm) {
        payload.toke = searchTerm
        payload.filter = "True"
      }
      const self = this;
      this.sendRequest(payload, this.url_rubros).then((data) => {
        self.list_rubros = data.data;
      }).catch((error) => {
        console.log(error);
        this.$swal.fire('Error!', 'No fue posible obtener los registros.', 'error');
      });
      return null;
    },
    get_list_libros(searchTerm=null) {
      const payload = {};
      if (searchTerm) {
        payload.toke = searchTerm
        payload.filter = "True"
      }
      filter: "True"
      const self = this;
      this.sendRequest(payload, this.url_rubros).then((data) => {
        self.list_libros = data.data;
      }).catch((error) => {
        console.log(error);
        this.$swal.fire('Error!', 'No fue posible obtener los registros.', 'error');
      });
      return null;
    },
    getValidationClass (fieldName) {
      const field = this.$v.instance[fieldName]

      if (field) {
        return {
          'md-invalid': field.$invalid && field.$dirty
        }
      }
    },
    clearForm () {
      this.$v.$reset()
    },
    validateUser () {
      this.$v.$touch()
      if (!this.$v.$invalid) {
        this.saveOrUpdate()
      }
    },
    saveOrUpdate () {
      const payload = this.instance
      const self = this
      this.sending = true
      if (this.instance.id) {
        this.putRequest(payload).then(function (data) {
          self.docuemt = `${self.instance.name}`
          self.userSaved = true
          self.sending = false
          self.instance = data.data
          self.clearForm()
        }).catch(function (error) {
          self.sending = false
          if (typeof (error.response.data) === 'object') {
            const result = error.response.data
            Object.entries(result).forEach(
              ([key, value]) => self.$swal.fire('Error!', key + ': ' + value, 'error')
            )
            return false
          }
          self.$swal.fire('Error!', error.response.data, 'error')
        })
        return
      }
      this.postRequest(payload).then(function (data) {
        self.docuemt = `${self.instance.name}`
        self.userSaved = true
        self.sending = false
        self.clearForm()
        self.instance = data.data
      }).catch(function (error) {
        self.sending = false
        if (typeof (error.response.data) === 'object') {
          const result = error.response.data
          Object.entries(result).forEach(
            ([key, value]) => self.$swal.fire('Error!', key + ': ' + value, 'error')
          )
          return false
        }
        self.$swal.fire('Error!', error.response.data, 'error')
      })
    },
    async retrieveInvoice () {
      const self = this
      let payload = {
        id: this.instance.id,
      }
      await this.retrieveRequest(this.url, payload).then(function (data) {
        self.instance = data.data
      }).catch(function (error) {
        self.handlerError(error)
      })
    },
    async sendRequest(payload, url) {
      const result = await this.$http.get(url,{params:  payload});
      return result;
    },
    async postRequest (payload) {
      const result = await this.$http.post(this.url, payload)
      return result
    },
    async putRequest (payload) {
      const result = await this.$http.put(`${this.url}${payload.id}/`, payload)
      return result
    },
    async retrieveRequest (url, _payload) {
      const result = await this.$http.get(url + _payload.id + '/', { params: _payload })
      return result
    },
  },
  filters: {
    status_to_text(value){
      if (value) return 'Sí';
      return 'No';
    }
  },
  computed: {
    settings_obj() {
      return this.$store.state.settings_obj;
    },
  },
};

</script>
<style lang="css" scoped>
 .success {
    font-weight: 500;
    background-color: #4aad09;
  }
  .warning {
    font-weight: 500;
    background-color: yellow;
  }
  .danger {
    font-weight: 500;
    background-color: red;
  }
  table {
    font-size: 2em;
  }
  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    transition: background-color 5000s ease-in-out 0s;
    -webkit-box-shadow: 0 0 0px 1000px #424242 inset;
  }
</style>
